// extracted by mini-css-extract-plugin
export var bold = "AccountCreditCampaignTable-module--bold--38529";
export var fontBold = "AccountCreditCampaignTable-module--fontBold--7a256";
export var label = "AccountCreditCampaignTable-module--label--98553";
export var listExamples = "AccountCreditCampaignTable-module--listExamples--9d913";
export var pill = "AccountCreditCampaignTable-module--pill--549b1";
export var pillSelected = "AccountCreditCampaignTable-module--pillSelected--285af";
export var row = "AccountCreditCampaignTable-module--row--28759";
export var rowTitle = "AccountCreditCampaignTable-module--rowTitle--89190";
export var secondColumn = "AccountCreditCampaignTable-module--secondColumn--64cee";
export var smallSize = "AccountCreditCampaignTable-module--smallSize--6c3b4";
export var subheader = "AccountCreditCampaignTable-module--subheader--17f91";
export var table = "AccountCreditCampaignTable-module--table--28fb9";
export var tableWrapper = "AccountCreditCampaignTable-module--tableWrapper--ec15c";
export var thirdColumn = "AccountCreditCampaignTable-module--thirdColumn--6cf5f";
export var titleRow = "AccountCreditCampaignTable-module--titleRow--769b8";
export var value = "AccountCreditCampaignTable-module--value--5eb26";