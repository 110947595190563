export const occupations = [
  { value: 'PERMANENT', label: 'Fast anställd' },
  {
    value: 'SELF_EMPLOYED',
    label: 'Egen företagare (min 2 år)',
  },
  { value: 'EARLY_PENSIONER', label: 'Förtidspensionär' },
  { value: 'PENSIONER', label: 'Pensionär' },
];
export const accommodations = [
  { value: 'RENTAL', label: 'Hyresrätt' },
  { value: 'HOUSE', label: 'Hus' },
  { value: 'TENANT_OWNERSHIP', label: 'Bostadsrätt' },
  { value: 'LODGER', label: 'Inneboende' },
];
export const children = [
  { value: 0, label: 'Inga' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5+' },
];
