// extracted by mini-css-extract-plugin
export var checkbox = "Checkbox-module--checkbox--9ffcb";
export var dark = "Checkbox-module--dark--7bbe5";
export var defaultLabel = "Checkbox-module--defaultLabel--7cc5d";
export var error = "Checkbox-module--error--79e76";
export var errorLabel = "Checkbox-module--errorLabel--08bdb";
export var large = "Checkbox-module--large--c9c38";
export var noSubLabel = "Checkbox-module--noSubLabel--0bc5d";
export var subLabel = "Checkbox-module--subLabel--ac1c7";
export var success = "Checkbox-module--success--848b6";
export var successLabel = "Checkbox-module--successLabel--5eecf";
export var wrapper = "Checkbox-module--wrapper--ccea5";