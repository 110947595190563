import { AccountCreditCampaign, ApplicationData } from '@src/types';
import {
  AccountCreditCampaign as AccountCreditCampaignGraphQL,
  AddWebAccountCreditApplicationDocument,
  AddWebAccountCreditApplicationMutation,
  AddWebAccountCreditApplicationMutationVariables,
  Children,
  ChronoUnit,
  Currency,
  GetWebAccountCreditApplicationDocument,
  GetWebAccountCreditApplicationIncreaseDocument,
  GetWebAccountCreditApplicationIncreaseQuery,
  GetWebAccountCreditApplicationIncreaseQueryVariables,
  GetWebAccountCreditApplicationQuery,
  GetWebAccountCreditApplicationQueryVariables,
  MonetaryAmountInput,
  MonetaryAmountUnit,
  AddWebAccountCreditApplicationIncreaseDocument,
  AddWebAccountCreditApplicationIncreaseMutation,
  AddWebAccountCreditApplicationIncreaseMutationVariables,
  SendApplicationDataInput
} from '../../graphql/generated';
import {
  loginWithSsn,
  removeEmpty,
  roundDownToNearest,
  stripNonDigits,
} from './utils';
import { Client } from 'urql';
import { LoanApplicationReduxType } from '@src/redux/loanApplication';

type TargetPage =
  | '/lana/offert'
  | '/lana/nekad'
  | '/lana/kreditavtal'
  | '/lana/befintligt-lan'
  | '/lana/befintlig-ansokan'
  | '/lana/nekad'
  | '/lana/konto'
  | '/lana/kompletteringskrav';

type ApplicationState =
  | 'WaitingForQuotationResponse'
  | 'Accepted'
  | 'LoanCreated'
  | 'Denied';

export const getMaxQuotationAmount = priceOffer =>
  Math.max.apply(
    null,
    priceOffer.matrix.map(o => o.loanAmount),
  );

export const getCurrentDebt = state => state.loanApplication.currentDebt || 0;

export const getTotalAmount = state =>
  (state.loanApplication.selectedAmount ||
    state.loanApplication.appliedAmount) + getCurrentDebt(state);

export const _getInterestRate = (priceOffer, totalAmount) => {
  const offerForCurrentAmount = priceOffer.matrix.find(
    offer => offer.loanAmount >= totalAmount,
  );
  if (!offerForCurrentAmount) {
    const foundMaxOffer = priceOffer.matrix.find(
      offer => offer.loanAmount === getMaxQuotationAmount(priceOffer),
    );
    return foundMaxOffer && foundMaxOffer.interest;
  }
  return offerForCurrentAmount.interest;
};

export const getInterestRate = state => {
  return _getInterestRate(
    state.loanApplication.priceOffer,
    getTotalAmount(state),
  );
};

export const getOriginalInterestRate = state =>
  state.loanApplication.beforePriceCampaign
    ? _getInterestRate(
        state.loanApplication.beforePriceCampaign,
        getTotalAmount(state),
      )
    : null;

export const getInitialSelectedAmount = (loanApplication, priceOffer) => {
  const { appliedAmount, currentDebt } = loanApplication;
  const totalAppliedAmount = currentDebt
    ? appliedAmount + currentDebt
    : appliedAmount;
  const maxQuotationAmount = getMaxQuotationAmount(priceOffer);
  const fullAmountWasApproved = maxQuotationAmount >= totalAppliedAmount;
  if (fullAmountWasApproved) {
    return appliedAmount;
  }

  // Max amount we can approve rounded down to closest 1000
  return roundDownToNearest(maxQuotationAmount - currentDebt, 1000);
};

export const validateApplication = loanApplication => {
  const {
    phone,
    email,
    occupation,
    accommodation,
    monthlySalary,
    nameOfEmployer,
    isLaidOff,
  } = loanApplication.isValid;

  const shouldAskForEmployer =
    loanApplication.occupation === 'PERMANENT' ||
    loanApplication.occupation === 'SELF_EMPLOYED';

  const employerValidations = shouldAskForEmployer
    ? [nameOfEmployer, isLaidOff]
    : [];

  const isInvalid = [
    phone,
    email,
    occupation,
    accommodation,
    monthlySalary,
    ...employerValidations,
  ].includes(false);

  return !isInvalid;
};

const getAdults = sharedHousehold => (sharedHousehold ? 2 : 1);
export const numberChildrenToChildrenInput = (children: number): Children => {
  switch (children) {
    case 0:
      return Children.NoChildren;
    case 1:
      return Children.OneChild;
    case 2:
      return Children.TwoChildren;
    case 3:
      return Children.ThreeChildren;
    case 4:
      return Children.FourChildren;
    case 5:
      return Children.FiveChildren;
    case 6:
    default:
      return Children.SixOrMore;
  }
};

export function formatDateLoanApplication(date: string): string {
  const arrayDate = date.split('/');
  const month = arrayDate[0];
  const year = arrayDate[1];

  return `${year}-${month}-01`;
}

export const getFormData = (loanApplication: LoanApplicationReduxType): SendApplicationDataInput => {
  let coApplicantData = undefined;

  if (loanApplication.withCoApplicant){
    coApplicantData = {
      nin: loanApplication.coApplicantSsn,
      contactDetails: {
        phoneNumber: stripNonDigits(loanApplication.coApplicantPhone),
        email: loanApplication.coApplicantEmail,
      },
      personalDetails: {
        maritalStatus: loanApplication.coApplicantMaritalStatus,
        children: loanApplication.coApplicantHasChildren ? numberChildrenToChildrenInput(loanApplication.coApplicantNumberOfChildren) : Children.NoChildren,
      },
      accommodation: {
        accommodationType: loanApplication.coApplicantAccommodation,
        monthlyCost: toMonetaryAmountMinor(
          loanApplication.coApplicantAccommodationMonthlyCost,
        ),
      },
      occupation: {
        occupationType: {
          companyName: loanApplication.coApplicantNameOfEmployer,
          companyPhone: loanApplication.coApplicantCompanyPhone,
          since: formatDateLoanApplication(loanApplication.coApplicantOccupationSince),
          occupation_type_name: loanApplication.coApplicantOccupation,
          to: loanApplication.coApplicantOccupationTo
            ? formatDateLoanApplication(loanApplication.coApplicantOccupationTo)
            : null,
        },
        monthlyIncome: toMonetaryAmountMinor(loanApplication.coApplicantMonthlySalary),
      }
    }
  }

  return {
    totalAmount: toMonetaryAmountMinor(loanApplication.appliedAmount),
    amortizationPeriod: {
      length: loanApplication.amortizationPeriod,
      chronoUnit: {
        name: ChronoUnit.Years,
      },
    },
    loanPurpose: loanApplication.loanPurpose,
    contactDetails: {
      phoneNumber: stripNonDigits(loanApplication.phone),
      email: loanApplication.email,
    },
    personalDetails: {
      maritalStatus: loanApplication.maritalStatus,
      children: loanApplication.hasChildren ? numberChildrenToChildrenInput(loanApplication.numberOfChildren) : Children.NoChildren,
    },
    accommodation: {
      accommodationType: loanApplication.accommodation,
      monthlyCost: toMonetaryAmountMinor(
        loanApplication.accommodationMonthlyCost,
      ),
    },
    occupation: {
      occupationType: {
        companyName: loanApplication.nameOfEmployer,
        companyPhone: loanApplication.companyPhone,
        since: formatDateLoanApplication(loanApplication.since),
        occupation_type_name: loanApplication.occupation,
        to: loanApplication.to
          ? formatDateLoanApplication(loanApplication.to)
          : null,
      },
      monthlyIncome: toMonetaryAmountMinor(loanApplication.monthlySalary),
    },
    consolidate:
      loanApplication.renegotiateLoansAmount > 0 &&
      loanApplication.renegotiateLoans
        ? {
            ofTotalToConsolidate: toMonetaryAmountMinor(
              loanApplication.renegotiateLoansAmount,
            ),
          }
        : undefined,
    coApplicantData
  };
};

const getTrackingDataPayload = trackingData =>
  JSON.stringify(removeEmpty(trackingData));

const toAccountCreditApplicationData = (
  application: any,
  campaign?: AccountCreditCampaign,
): AddWebAccountCreditApplicationMutationVariables['input'] => {
  const gaClientId = localStorage.getItem('gaClientId');
  const channel = localStorage.getItem('channel') || '0'; // default channel is "New Site"
  const atGd = localStorage.getItem('at_gd');
  const isAffiliate = channel === '533';
  const cn = isAffiliate ? localStorage.getItem('cn') : null;
  const cv = isAffiliate ? localStorage.getItem('cv') : null;
  const trackingData = getTrackingDataPayload({
    gaClientId,
    cn,
    cv,
    atGd,
    website: 'rocker.com',
    campaign: campaign,
  });

  return {
    phoneNumber: application.phone as string,
    email: application.email,
    employmentLength: -1,
    kalp: {
      income: application.monthlySalary,
      employmentType: application.occupation,
      housingType: application.accommodation,
      children: application.numberOfChildren,
      adults: getAdults(application.sharedHousehold),
    },
    channel: String(application.channel || channel),
    trackingId: trackingData,
    appliedWithCampaign:
      campaign === undefined
        ? AccountCreditCampaignGraphQL.ZeroInterestTwoMonths
        : AccountCreditCampaignGraphQL[campaign],
  };
};

export async function applyForAccountCredit({
  authStarted,
  authCompleted,
  urqlClient,
  setLoggedIn,
  accountCreditApplication,
  updateAccountCreditDetails,
  pathPrefix,  
  campaign = undefined,
}) {
  authStarted();
  const data = toAccountCreditApplicationData(
    accountCreditApplication,
    campaign,
  );

  await loginWithSsn(urqlClient, accountCreditApplication.ssn, setLoggedIn)
  try {
    const { data: dataDecision, error: errorDecision } = await (urqlClient as Client)
      .mutation<
        AddWebAccountCreditApplicationMutation,
        AddWebAccountCreditApplicationMutationVariables
      >(
        AddWebAccountCreditApplicationDocument,
        { input: data }
      )
      .toPromise();

    if (dataDecision.addWebAccountCreditApplication.decision === 'APPROVED') {
      const { data: dataApplication } = await (urqlClient as Client)
        .query<
          GetWebAccountCreditApplicationQuery,
          GetWebAccountCreditApplicationQueryVariables
        >(
          GetWebAccountCreditApplicationDocument,
          {}
        )
        .toPromise();

      if (dataApplication.getWebAccountCreditApplication) {
        updateAccountCreditDetails(
          dataApplication.getWebAccountCreditApplication,
        );
        authCompleted();
        return pathPrefix + '/kreditavtal';
      }
    } else if (
      dataDecision?.addWebAccountCreditApplication?.decision === 'NOT_QUALIFIED'
    ) {
      return '/lana/befintligt-lan';
      // INTERNAL SERVER error means that there is already an application in progress
    } else if (
      dataDecision.addWebAccountCreditApplication.decision ===
        'Account credit already exists' ||
      errorDecision[0]?.extensions?.code === 500
    ) {
      console.log(
        'Make an attempt to increase the account credit (after Internal Error)',
      );
      return await increaseAccountCreditLimit({
        authCompleted,
        urqlClient,
        accountCreditApplication,
        updateAccountCreditDetails,
        pathPrefix,
        campaign,
      });
    } else {
      authCompleted();
      return pathPrefix + '/nekad';
    }
  } catch (e) {
    console.error('Caught an error in applyForAccountCredit: ', e);
    console.log(
      'Make an attempt to increase the account credit (after Internal Error)',
    );
    return await increaseAccountCreditLimit({
      authCompleted,
      urqlClient,
      accountCreditApplication,
      updateAccountCreditDetails,
      pathPrefix,
      campaign,
    });
  } finally {
    authCompleted();
  }
}

export async function increaseAccountCreditLimit({
  authCompleted,
  urqlClient,
  accountCreditApplication,
  updateAccountCreditDetails,
  pathPrefix,
  campaign = undefined,
}) {
  const data = toAccountCreditApplicationData(
    accountCreditApplication,
    campaign,
  );

  const { data: dataApply } = await (urqlClient as Client)
    .mutation<
      AddWebAccountCreditApplicationIncreaseMutation,
      AddWebAccountCreditApplicationIncreaseMutationVariables
    >(AddWebAccountCreditApplicationIncreaseDocument, {
      input: data,
    })
    .toPromise();

  if (
    dataApply?.addWebAccountCreditApplicationIncrease?.decision === 'APPROVED'
  ) {
    const { data: dataIncrease } = await (urqlClient as Client)
      .query<
        GetWebAccountCreditApplicationIncreaseQuery,
        GetWebAccountCreditApplicationIncreaseQueryVariables
      >(GetWebAccountCreditApplicationIncreaseDocument, {})
      .toPromise();

    updateAccountCreditDetails({
      ...dataIncrease.getWebAccountCreditApplicationIncrease,
    });
    authCompleted();
    return pathPrefix + '/kreditavtal-okning';
  } else {
    authCompleted();
    return pathPrefix + '/nekad';
  }
}

export const getPageFromCreateApplicationResult: (
  _: string,
) => TargetPage = result => {
  if (result === 'LoanExists') {
    return '/lana/befintligt-lan';
  } else if (result === 'LoanApplicationExists') {
    return '/lana/befintlig-ansokan';
  }
};

type GetPageFromApplicationData = (
  applicationData: ApplicationData,
  isApplicationLocked?: boolean,
) => TargetPage;
export const getPageFromApplicationData: GetPageFromApplicationData = (
  data,
  locked,
) => {
  if (locked) {
    return '/lana/offert';
  }

  if (data.state === 'Accepted' && !!data.bankAccount) {
    return '/lana/kreditavtal';
  }

  const stateToPage: Record<ApplicationState, TargetPage> = {
    WaitingForQuotationResponse: '/lana/offert',
    Accepted: '/lana/konto',
    LoanCreated: '/lana/befintligt-lan',
    Denied: '/lana/nekad',
  };

  return stateToPage[data.state];
};

export function toMonetaryAmountMinor(
  amount: number | string,
): MonetaryAmountInput {
  return {
    amount: Number(amount) * 100,
    currency: Currency.Sek,
    unit: MonetaryAmountUnit.Minor,
  };
}
