import React from 'react';
import TextInput, { TextInputProps } from './TextInput';
import { AsYouType } from 'libphonenumber-js';

type PhoneInputProps = Omit<TextInputProps, 'ref'>;

const asYouType = new AsYouType('SE');

export function PhoneInput({
  onChange,
  onBlur,
  placeholder,
  ...props
}: PhoneInputProps) {
  const _onChange = e => {
    asYouType.reset();
    const newValue =
      e.target.value.length > 3
        ? asYouType.input(e.target.value)
        : e.target.value;
    e.target.value = newValue;
    onChange?.(e);
  };

  const _onBlur = e => {
    asYouType.reset();
    const newValue =
      e.target.value.length > 3
        ? asYouType.input(e.target.value)
        : e.target.value;
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    ).set;

    nativeInputValueSetter.call(e.target, newValue);
    onBlur?.(e);
  };

  return (
    <TextInput
      type="tel"
      maxLength={64}
      onChange={_onChange}
      placeholder={placeholder || '073-123 45 67'}
      onBlur={_onBlur}
      {...props}
    />
  );
}
