import React from 'react';
import { graphql } from 'gatsby';
import { useMedia } from '@lib/hooks';
import { ContentfulTextSection } from '@src/../graphql-types';
import { ContentfulCardsSectionComponent } from '@src/contentful';
import SEO from '@components/seo';
import Markdown from '@src/components/markdown';
import * as styles from '@components/TextHero/TextHero.module.scss';
import Layout from '@components/Layout/Layout';
import SubNavPills from '@components/SubNavPills/SubNavPills';
import Container from '@components/Container';
import Grid, { Row } from '@components/Grid';
import UspList from '@components/UspList/UspList';
import AccountCreditFormCard from '@src/components/AccountCreditFormCard';
import CtaCards from '@src/components/CtaCards/CtaCardsSmall';
import lanPages from '@src/data/lanPages';
import useSearchParam from '@src/hooks/useSearchParam.hook';
import classNames from 'classnames';
import AccountCreditCampaignTable from '@src/components/AccountCreditCampaignTable';
import '@src/sentry'; // Enable Sentry monitoring for errors
import {
  ContentfulCard,
  ContentfulQuestion,
  KreditQuery,
} from '../../graphql-types';

type KreditProps = {
  data: KreditQuery;
};

const Kredit = ({ data }: KreditProps) => {
  const adtractionId = useSearchParam('at_gd');
  if (adtractionId) {
    localStorage.setItem('at_gd', adtractionId);
  }

  const headLine = data.contentfulPage.headLine;
  const { questions, questionsHeading } = data.contentfulPage;
  const {
    pageHeader,
    pageHeaderDescription: { pageHeaderDescription },
    pageContent,
  } = data.contentfulPage;

  const fineprint = pageContent[5] as ContentfulTextSection;
  const cardsSection = pageContent[6] as ContentfulCardsSectionComponent;
  const creditBrokerNordiskaSection = pageContent[8] as ContentfulTextSection;

  const classes = classNames({
    [styles.textHero]: true,
    [styles.leftAligned]: true,
    [styles.sticky]: true,
    [styles.mediumSize]: true,
  });

  const splits = pageHeader?.split('&nbsp;');
  const isMobile = useMedia(['(max-width: 896px)'], [true], false);

  return (
    <Layout
      path="/kredit"
      theme="soft"
      questions={questions as ContentfulQuestion[]}
      questionsHeading={questionsHeading}
    >
      <SEO title={headLine} />
      <SubNavPills theme="soft" links={lanPages} />
      <Container style={{ paddingTop: isMobile ? '32px' : '48px' }}>
        <Grid>
          <div style={{ maxWidth: '448px' }}>
            <header
              className={classes}
              style={{
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <div id="TextHero">
                <h1>
                  {splits.map((s, i) => (
                    <span key={i}>
                      {s}
                      {i < splits.length - 1 ? <>&nbsp;</> : ''}
                    </span>
                  ))}
                </h1>
              </div>
              <UspList
                items={[
                  {
                    text: '20 000 kr utan ränta eller avgift i två månader',
                    icon: 'Dollar',
                  },
                  { text: 'Betala tillbaka när du vill', icon: 'Calendar' },
                  {
                    text:
                      'Pengarna tillgängliga i Rockerappen inom ett par minuter',
                    icon: 'CreditCard',
                  },
                  {
                    text: (
                      <Markdown>
                        Ansök senast **2023-06-15** för att ta del av
                        erbjudandet
                      </Markdown>
                    ),
                    icon: 'Plug',
                  },
                ]}
                showVerticalListMobile={true}
              />

              <Markdown className={styles.subtitle}>
                {pageHeaderDescription}
              </Markdown>
              <div className={styles.children}>
                <div
                  style={{
                    fontSize: '70%',
                    lineHeight: '90%',
                    fontStyle: 'italic',
                  }}
                >
                  {creditBrokerNordiskaSection.content.content}
                </div>
                {isMobile && (
                  <div
                    style={{
                      marginTop: '24px',
                    }}
                  >
                    <AccountCreditFormCard
                      // @ts-ignore
                      pathPrefix="/kredit"
                      campaign={undefined}
                      fineprint={fineprint}
                      title="Ansökan Rocker Extra - kontokredit"
                      {...data}
                    />
                    <div style={{ marginTop: '20px' }}>
                      <AccountCreditCampaignTable exampleText="räkningar" />
                    </div>
                  </div>
                )}
              </div>
            </header>
          </div>

          {!isMobile && (
            <AccountCreditFormCard
              // @ts-ignore
              pathPrefix="/kredit"
              campaign={undefined}
              fineprint={fineprint}
              title="Ansökan Rocker Extra - kontokredit"
              {...data}
            />
          )}

          <Row>
            {!isMobile && (
              <div>
                <AccountCreditCampaignTable exampleText="räkningar" />
              </div>
            )}
            <CtaCards
              cards={cardsSection.card as ContentfulCard[]}
              style={{ marginBottom: 20 }}
            />
          </Row>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Kredit;

export const query = graphql`
  query Kredit {
    contentfulPage(pageUrl: { eq: "/kredit2.0" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      questionsHeading
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCard {
          additionalLinkPath
          additionalLinkText
          linkPath
          linkText
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
        }
        ... on ContentfulTextSection {
          overline
          subtitle
          title
          content {
            content
          }
        }
        ... on ContentfulCardsSectionComponent {
          entryTitle
          card {
            additionalLinkPath
            additionalLinkText
            subtitle
            title
            linkText
            linkPath
          }
        }
      }
      pageContactHeader
    }
  }
`;
