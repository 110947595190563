import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { useMemo } from 'react';

/**
 *
 * @param {String} identifier The Contentful identifier of the text section.
 * @returns Text Section
 */
const useTextSection = (identifier: string) => {
  const { locale } = useIntl();
  const {
    allContentfulTextSection: { edges: allTextSections },
  } = useStaticQuery(graphql`
    query TextSectionsQuery {
      allContentfulTextSection(filter: { identifier: { ne: null } }) {
        edges {
          node {
            node_locale
            identifier
            overline
            title
            subtitle
            content {
              content
            }
            image {
              file {
                url
              }
              gatsbyImageData(width: 1040)
              title
            }
          }
        }
      }
    }
  `);

  const m = useMemo(
    () =>
      allTextSections.find(
        c => c.node.identifier === identifier && c.node.node_locale === locale,
      ),
    [allTextSections],
  );

  if (!m) {
    return null;
  }

  const n = m.node;

  return {
    overline: n.overline,
    title: n.title,
    subtitle: n.subtitle,
    content: n.content ? n.content.content : undefined,
    image: n.image,
    identifier: n.identifier,
  };
};

export default useTextSection;
