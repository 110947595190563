import classNames from 'classnames';
import React, { useRef } from 'react';
import Icon from '../Icon/Icon';
import { InputStatus } from '../TextInput';

import * as styles from './SelectInput.module.scss';

type SelectOption = {
  label: string;
  value: string | number;
};

type SelectInputProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  options: SelectOption[];
  value: string | number | undefined;
  onSelectItem?(value: string | number): void;
  onChange?: React.FormEventHandler<HTMLSelectElement>;
  status?: InputStatus;
  className?: string;
  wrapperClassName?: string;
  isDirty?: boolean;
  label?: string;
  errorLabel?: string;
  successLabel?: string;
  subLabel?: string;
  performValidation?: (_) => boolean;
  placeholder?: string;
  id?: string;
};

function SelectInput({
  label,
  options,
  onChange,
  onSelectItem,
  children,
  value,
  status,
  className,
  wrapperClassName,
  isDirty,
  id,
  errorLabel,
  successLabel,
  subLabel,
  placeholder,
  performValidation,
  ...props
}: SelectInputProps) {
  const selectRef = useRef<HTMLSelectElement>();
  const _onChange = e => {
    const selected = options[selectRef.current.selectedIndex - 1];
    performValidation?.(selected.value);
    onSelectItem?.(selected.value);
    onChange?.(e);
  };

  const dirty = isDirty;

  const val = typeof value === 'undefined' || value === null ? '' : value;

  const classes = classNames({
    [styles.input]: true,
    [styles.success]: status === 'success' && dirty,
    [styles.error]: status === 'error' && dirty,
    [styles.nothingSelected]: val === '',
    [className]: className,
  });

  const wrapperClasses = classNames({
    [styles.wrapper]: true,
    [styles.error]: status === 'error' && dirty && errorLabel,
    [styles.success]: status === 'success' && dirty && successLabel,
    [styles.noSubLabel]: !errorLabel && !successLabel && !subLabel,
    [wrapperClassName]: wrapperClassName,
  });

  return (
    <div className={wrapperClasses}>
      {label && (
        <label className={styles.topLabel} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <Icon name="ChevronDown" className={styles.icon} />
        <select
          id={id}
          ref={selectRef}
          className={classes}
          onChange={_onChange}
          value={val}
          aria-invalid={status === 'error'}
          aria-errormessage={
            status === 'error' && errorLabel ? errorLabel : undefined
          }
          {...props}
        >
          <option value="" style={{ opacity: 0.1 }} disabled>
            {placeholder}
          </option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
          {children}
        </select>
      </div>
      {errorLabel && (
        <div className={classNames([styles.subLabel, styles.errorLabel])}>
          {errorLabel}
        </div>
      )}
      {subLabel && (
        <div className={classNames([styles.subLabel, styles.defaultLabel])}>
          {subLabel}
        </div>
      )}
    </div>
  );
}

export default SelectInput;
