import Icon from '@components/Icon/Icon';
import classNames from 'classnames';
import React, { useState } from 'react';

import * as styles from './Checkbox.module.scss';

type CheckboxProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'size'> & {
  id: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onToggle?: (checked: boolean) => void;
  errorLabel?: string;
  status?: 'error' | 'default' | 'success';
  size?: 'normal' | 'large';
  isDirty?: boolean;
};

function Checkbox({
  checked,
  id,
  disabled,
  label,
  children,
  size,
  errorLabel,
  status,
  onToggle,
  onChange,
  isDirty,
  ...props
}: CheckboxProps) {
  const [_dirty, setDirty] = useState(false);
  const dirty = isDirty || _dirty;
  const classes = classNames({
    [styles.wrapper]: true,
    [styles.disabled]: disabled,
    [styles.large]: size === 'large',
    [styles.error]: status === 'error' && dirty && errorLabel,
    [styles.noSubLabel]: !errorLabel,
  });

  const iconSize = size === 'large' ? 18 : 14;

  const _onChange = e => {
    setDirty(true);
    onToggle?.(e.target.checked);
    onChange?.(e);
  };

  return (
    <div className={classes}>
      <input
        type="checkbox"
        disabled={disabled}
        id={id}
        checked={checked}
        onChange={_onChange}
        {...props}
      />
      <label
        htmlFor={id}
        aria-label={typeof children === 'string' ? children : label}
      >
        <div className={styles.checkbox}>
          <Icon name="Check" width={iconSize} height={iconSize} color="white" />
        </div>
        {label || children}
      </label>
      {errorLabel && (
        <div className={classNames([styles.subLabel, styles.errorLabel])}>
          {errorLabel}
        </div>
      )}
    </div>
  );
}

export default Checkbox;
