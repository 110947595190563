import React from 'react';
import { prependCentury } from '../../lib/utils';
import TextInput, { TextInputProps } from './TextInput';

type SsnInputProps = Omit<TextInputProps, 'ref'>;

export function SsnInput({ onChange, onBlur, ...props }: SsnInputProps) {
  const _onBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    const input = e.target;
    const newValue = prependCentury(input.value);
    if (newValue !== input.value) {
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      ).set;

      nativeInputValueSetter.call(input, newValue);
      var inputEvent = new Event('input', { bubbles: true });
      input.dispatchEvent(inputEvent);
    }

    onBlur?.(e);
  };

  return (
    <TextInput
      maxLength={14}
      onChange={onChange}
      placeholder="196408233234"
      onBlur={_onBlur}
      {...props}
    />
  );
}
