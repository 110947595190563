import React, { useState } from 'react';
import classNames from 'classnames';
import Container from '@components/Container';
import * as styles from './AccountCreditCampaignTable.module.scss';
import data1 from './data.json';
import { formatMoney } from '@src/lib/utils';

const titleRows = ['', '2 månader', '6 månader'];

function AccountCreditCampaignTable({ exampleText }: { exampleText: string }) {
  const [selectedAmount, setSelectedAmount] = useState<number>(15000);
  const renderRow = (row, i) => {
    const isLastRow = data1.data[5000].length - 1 === i;
    return (
      <div className={classNames([styles.row])} key={row.title}>
        <div>
          <div className={styles.rowTitle}>{row.title}</div>
        </div>
        <div
          className={classNames({
            [styles.value]: true,
            [styles.secondColumn]: true,
            [styles.smallSize]: i === 3,
            [styles.fontBold]: isLastRow,
          })}
        >
          {row.value}
        </div>
        <div
          className={classNames({
            [styles.value]: true,
            [styles.thirdColumn]: true,
            [styles.smallSize]: i === 0,
            [styles.fontBold]: isLastRow,
          })}
        >
          {row.value2}
        </div>
      </div>
    );
  };

  const listAmountExample = Object.keys(data1.data);

  return (
    <Container className={styles.tableWrapper}>
      <h3 className={styles.label}>Vad kostar det?</h3>

      {/* List buttons example */}
      <div className={styles.listExamples}>
        {listAmountExample.map(amount => (
          <button
            key={amount}
            onClick={() => setSelectedAmount(Number(amount))}
            className={classNames({
              [styles.pill]: true,
              [styles.pillSelected]: amount === String(selectedAmount),
            })}
          >
            {formatMoney(amount)} kr
          </button>
        ))}
      </div>

      <p className={styles.subheader}>
        Nedan ser du exempel på vad det kostar att skjuta upp {exampleText} på{' '}
        <span className={styles.bold}>{formatMoney(selectedAmount)} kr</span> i
        två respektive sex månader.
      </p>
      <div className={styles.table}>
        {/* Title row */}
        {titleRows.map((v, i) => (
          <div
            key={v}
            className={classNames({
              [styles.rowTitle]: true,
              [styles.secondColumn]: i === 1,
              [styles.thirdColumn]: i === 2,
            })}
          >
            {v}
          </div>
        ))}

        {/* Data rows */}
        {data1.data[selectedAmount].map(renderRow)}
      </div>
    </Container>
  );
}

export default AccountCreditCampaignTable;
