import Checkbox from '@components/Checkbox/Checkbox';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby';
import { scroller } from 'react-scroll';
import apiSlice from '../../redux/api';
import accountCreditApplicationSlice from '@src/redux/accountCreditApplication';
import * as LoanApplicationUtils from '@src/lib/LoanApplicationUtils';
import * as Options from '@lib/creditOptions';
import * as ThirdPartyTracking from '@lib/thirdPartyTracking';
import useTextSection from '../../data/useTextSection';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import * as styles from './AccountCreditFormCard.module.scss';
import classNames from 'classnames';
import TextInput from '../TextInput/TextInput';
import { SsnInput, PhoneInput } from '../TextInput';
import Card from '../Card';
import AnimateHeight from 'react-animate-height';
import Markdown from '../markdown';
import SelectInput from '../SelectInput/SelectInput';
import { encodeSHA256 } from '../../lib/utils';
import { ContentfulTextSection } from '../../../graphql-types';
import { AccountCreditCampaign } from '@src/types';
import { useClient } from 'urql';
import appSlice from '@src/redux/app';

const errorMessages = {
  ssn: 'Ange ett giltigt personnummer',
  phone: 'Ange ett giltigt telefonnummer',
  email: 'Ange en giltig epostadress',
  monthlySalary: `Ange din månadsinkomst (minst 10 tkr, max 140 tkr)`,
  occupation: 'Ange en sysselsättning',
  accommodation: 'Ange en boendeform',
  numberOfChildren: 'Ange ett antal av barn',
  nameOfEmployer: 'Ange ett giltigt företagsnamn',
  laidOff: 'Fyll i fältet',
};

type Props = {
  title: ReactNode;
  style?: CSSProperties;
  validateSsn?: (_: string) => boolean;
  validateEmail?: (_: string) => boolean;
  validatePhone?: (_: string) => boolean;
  validateMonthlySalary?: (_: any) => boolean;
  validateOccupation?: (_: string) => boolean;
  validateNumberOfChildren?: (_: any) => boolean;
  validateAccommodation?: (_: string) => boolean;
  updateEmail?: (value: string) => void;
  updatePhone?: (value: string) => void;
  updateMonthlySalary?: (arg0: number) => void;
  updateOccupation?: (occupation: string) => void;
  updateSharedHousehold?: (checked: boolean) => void;
  updateMultipleChildren?: (checked: boolean) => void;
  updateNumberOfChildren?: (value: string | number) => void;
  updateAccommodation?: (value: string | number) => void;
  updateSsn?: (ssn: string) => void;
  updateError?: (message: string) => void;
  pathPrefix: string;
  campaign?: AccountCreditCampaign; // TODO: set the campaign in the redux store for the account credit, so that we can use it in the kreditavtal pages
  fineprint: ContentfulTextSection;
  customCtaText?: string;
  authStarted?: () => void;
  authCompleted?: () => void;
  setLoggedIn: (loggedIn : boolean) => void;
  accountCreditApplication?: any;
  updateAccountCreditDetails?: any;
};

function AccountCreditFormCard(props: Props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const urqlClient = useClient();
  const { accountCreditApplication, customCtaText, fineprint } = props;

  const loanCalculationExample = useTextSection('CreditFormCalculationExample');

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isExpanded) {
      return;
    }

    const {
      authStarted,
      authCompleted,
      setLoggedIn,
      accountCreditApplication,
      updateAccountCreditDetails,
      campaign,
      pathPrefix,
    } = props;

    const toSubmit = {
      authStarted,
      authCompleted,
      urqlClient,
      setLoggedIn: () => setLoggedIn(true),
      accountCreditApplication,
      updateAccountCreditDetails,
      pathPrefix,
      campaign,
    };

    setIsSubmitted(true);
    if (!LoanApplicationUtils.validateApplication(accountCreditApplication)) {
      return scroller.scrollTo('MainFormFields', {
        duration: 300,
        smooth: true,
        offset: -88,
      });
    }

    try {
      ThirdPartyTracking.accountCreditSubmitting();
      const targetPage = await LoanApplicationUtils.applyForAccountCredit(
        toSubmit,
      );

      const ssn_id = await encodeSHA256(accountCreditApplication.ssn.value);
      if (targetPage === pathPrefix + '/kreditavtal') {
        ThirdPartyTracking.accountCreditApproved(ssn_id);
      } else if (targetPage === pathPrefix + '/nekad') {
        ThirdPartyTracking.accountCreditDenied(ssn_id);
      }

      navigate(targetPage);
    } catch (e) {
      props.updateError(e.message);
      props.authCompleted();
    }
  };

  const onSsnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(true);
    const ssn = e.target.value;
    props.updateSsn(ssn);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(true);
    props.updateEmail(e.target.value);
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(true);
    props.updatePhone(e.target.value);
  };

  const onSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(true);
    props.updateMonthlySalary(Number(e.target.value));
  };

  const onOccupationChange = (occupation: string) => {
    setIsExpanded(true);
    props.updateOccupation(occupation);
  };

  const trackOnPrimaryBtn = () => {
    ThirdPartyTracking.accountCreditApplyStart();
    setIsExpanded(true);
  };

  return (
    <Card className={styles.formWrapper} freeSize={isExpanded}>
      <h3>{props.title}</h3>
      {!isExpanded && (
        <div className={styles.expandForm}>
          <PrimaryButton onClick={trackOnPrimaryBtn} type="button">
            {customCtaText || 'Påbörja ansökan'}
          </PrimaryButton>
        </div>
      )}
      <form
        id="creditForm"
        className={classNames({
          [styles.container]: true,
          [styles.expanded]: isExpanded,
        })}
        onSubmit={submitForm}
        style={props.style}
      >
        <div className={styles.formSection}>
          <div id="MainFormFields" />
          <SsnInput
            label="Personnummer"
            style={{ width: '100%' }}
            value={accountCreditApplication.ssn}
            onChange={onSsnChange}
            status={accountCreditApplication.isValid.ssn ? 'default' : 'error'}
            errorLabel={errorMessages.ssn}
            performValidation={props.validateSsn}
            isDirty={isSubmitted}
          />
          <TextInput
            label="E-post"
            placeholder="rocker@rocker.com"
            id="email"
            type="email"
            value={accountCreditApplication.email}
            onChange={onEmailChange}
            errorLabel={errorMessages.email}
            status={
              accountCreditApplication.isValid.email ? 'default' : 'error'
            }
            performValidation={props.validateEmail}
            isDirty={isSubmitted}
          />
          <PhoneInput
            label="Mobiltelefon"
            id="phone"
            value={accountCreditApplication.phone}
            onChange={onPhoneChange}
            status={
              accountCreditApplication.isValid.phone ? 'default' : 'error'
            }
            errorLabel={errorMessages.phone}
            performValidation={props.validatePhone}
            isDirty={isSubmitted}
          />
          {!isExpanded && (
            <div className={styles.infoText}>
              <Markdown openLinksInNewTab>
                {loanCalculationExample.content}
              </Markdown>
            </div>
          )}
          <div className={styles.formSection}>
            <TextInput
              min={0}
              label="Månadsinkomst (före skatt)"
              placeholder="Månadsinkomst (före skatt)"
              id="monthlySalary"
              type="number"
              value={accountCreditApplication.monthlySalary || ''}
              status={
                accountCreditApplication.isValid.monthlySalary
                  ? 'default'
                  : 'error'
              }
              onChange={onSalaryChange}
              errorLabel={errorMessages.monthlySalary}
              performValidation={props.validateMonthlySalary}
              isDirty={isSubmitted}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        <AnimateHeight height={isExpanded ? 'auto' : 1}>
          <div>
            <SelectInput
              id="occupation"
              label="Sysselsättning"
              placeholder="Ange sysselsättning"
              options={Options.occupations}
              value={accountCreditApplication.occupation}
              onSelectItem={onOccupationChange}
              status={
                accountCreditApplication.isValid.occupation
                  ? 'default'
                  : 'error'
              }
              isDirty={isSubmitted}
              errorLabel={errorMessages.occupation}
              performValidation={props.validateOccupation}
            />
          </div>
          <div className={styles.extraContent}>
            <div className={styles.formSection}>
              <SelectInput
                id="accommodation"
                label="Boendeform"
                placeholder="Ange boendeform"
                options={Options.accommodations}
                value={accountCreditApplication.accommodation}
                onSelectItem={props.updateAccommodation}
                status={
                  accountCreditApplication.isValid.accommodation
                    ? 'default'
                    : 'error'
                }
                isDirty={isSubmitted}
                errorLabel={errorMessages.accommodation}
                performValidation={props.validateAccommodation}
              />
            </div>
            <div className={styles.formSection} style={{ marginBottom: 48 }}>
              <Checkbox
                checked={accountCreditApplication.sharedHousehold}
                label="Jag delar boende med en vuxen"
                onToggle={props.updateSharedHousehold}
                id="sharedHouseholdCheck"
              />
              <div
                className={
                  accountCreditApplication.multipleChildren
                    ? undefined
                    : styles.childrenToggleContainer
                }
              >
                <Checkbox
                  checked={accountCreditApplication.multipleChildren}
                  label="Jag har barn"
                  id="haveChildrenCheck"
                  onToggle={props.updateMultipleChildren}
                />
                {/* @ts-ignore */}
                <AnimateHeight
                  height={
                    accountCreditApplication.multipleChildren ? 'auto' : 0
                  }
                >
                  <div style={{ paddingTop: '16px' }}>
                    <SelectInput
                      id="numberOfChildren"
                      label="Antal barn"
                      subLabel="Räkna endast med dina barn som bor hemma och som ännu inte fyllt 18 år."
                      placeholder="Ange antal barn"
                      options={Options.children}
                      value={accountCreditApplication.numberOfChildren}
                      onSelectItem={props.updateNumberOfChildren}
                      status={
                        accountCreditApplication.isValid.numberOfChildren
                          ? 'default'
                          : 'error'
                      }
                      isDirty={isSubmitted}
                      errorLabel={errorMessages.numberOfChildren}
                      performValidation={props.validateNumberOfChildren}
                    />
                  </div>
                </AnimateHeight>
              </div>
            </div>

            <PrimaryButton
              type="submit"
              icon="BankId"
              style={{ width: '100%' }}
            >
              Ansök nu - svar direkt
            </PrimaryButton>

            <div className={styles.infoText}>
              {fineprint.content?.content && <Markdown openLinksInNewTab>{fineprint.content?.content}</Markdown>}
            </div>
          </div>
        </AnimateHeight>
      </form>
    </Card>
  );
}

const mapStateToProps = state => ({
  accountCreditApplication: state.accountCreditApplication,
});

const mapDispatchToProps = dispatch => {
  const { authStarted, authCompleted } = apiSlice.actions;
  const setLoggedIn = appSlice.actions.setLoggedIn;
  return bindActionCreators(
    {
      ...accountCreditApplicationSlice.actions,
      authStarted,
      authCompleted,
      setLoggedIn,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountCreditFormCard);
